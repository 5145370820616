.slider {
    width: 100vw;
    height: 80vh;
    overflow: hidden;
    position: relative;
}

.slider .wrapper {
    height: 100vh;
    transition: .5s all linear;
    will-change: transform;
}

.slider .arrows {
    position: absolute;
    top: 50%;
    background: none;
    height: 60px;
    border: 0;
    cursor: pointer;
    transition: ease .3s all;
    outline: none;
}

.slider .arrows.prev {
    left: 0;
}

.slider .arrows.prev:hover {
    opacity: .7;
    left: -10px;
}

.slider .arrows.next {
    right: 0;

}

.slider .arrows.next:hover {
    right: -10px;
    opacity: .7;
}

.slider .dots-container {
    height: auto;
    margin: 0;
    padding: 0;
    position: absolute;
    width: auto;
    text-align: center;
    left: 50%;
    bottom: 9px;
    transform: translateX(-50%);
    z-index: 10;
    list-style-type: none;
}

.slider .dots-container li {
    display: inline-block;
    padding: 5px;
}

.slider .dots-container li.active button {
    color: #CD252B;
}

.slider .dots-container button {
    color: #fff;
    background-color: transparent;
    border: none;
}

.slider .dots-container button:hover {
    text-decoration: none;
    opacity: .7;
    cursor: pointer;
}

.slider .toggle-play {
    background: transparent;
    border: none;
    height: auto;
    position: absolute;
    width: auto;
    right: 5%;
    bottom: 9px;
    color: #3d3d3d;
    z-index: 1000000;
}

.slider .toggle-play:hover {
    text-decoration: none;
    opacity: .7;
    cursor: pointer;
}

.slider .each-slide {
    width: 100vw;
    height: 100vh;
    float: left;
    line-height: 100vh;
    text-align: center;
    background-size: cover;
    background-position: center center;
    background-color: transparent;
}